import { types } from 'mobx-state-tree';

const { model, literal, string, array } = types;

const AboutSectionModel = model('AboutSectionModel', {
  type: literal('about'),
  firstname: string,
  lastname: string,
  asset: string,
  title: string,
  descr: string,
  tagline: array(string)
});

export default AboutSectionModel;