import { types } from 'mobx-state-tree';

const { model, string } = types;

const LinkItemDetails = model('LinkItemDetails', {
  name: string,
  icon: string,
  url: string,
});

export default LinkItemDetails;