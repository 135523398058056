import React from 'react';
import PropTypes from 'prop-types';

import {
  CopyrightFooter,
  ActionFooter,
  InformationModalFooterProps
} from '@misc';

import { ActionModalFooter, CopyrightModalFooter } from './components';

import './style.scss';
import './medias.scss';

const InformationModalFooter = (props: InformationModalFooterProps) => {
  const {
    footer
  } = props;

  return (
    <div className="information-modal-footer">
      {footer.type === 'copyright' ? <CopyrightModalFooter {...footer as CopyrightFooter} />
        : footer.type === 'action' ? <ActionModalFooter {...footer as ActionFooter} />
        : (<></>)}
    </div>
  )
};

InformationModalFooter.propTypes = {
  footer: PropTypes.oneOfType([
    PropTypes.shape({
      type: PropTypes.oneOf([ 'copyright' ]),
      yearStart: PropTypes.number.isRequired,
      yearEnd: PropTypes.number,
      owner: PropTypes.string.isRequired
    }),
    PropTypes.shape({
      type: PropTypes.oneOf([ 'action' ]),
      onConfirm: PropTypes.func.isRequired,
      onCancel: PropTypes.func.isRequired
    })
  ]).isRequired,
};

InformationModalFooter.defaultProps = {};

export default InformationModalFooter;