import React, { useState } from 'react';
import ReactBnbGallery from 'react-bnb-gallery';
import PropTypes from 'prop-types';

import { ImageGalleryModalProps, ImageDetails } from '@misc';

import 'react-bnb-gallery/dist/style.css';

const ImageGalleryModal = (props: ImageGalleryModalProps) => {
  const {
    images,
    keepCurrentOnClose,
    // hideControlsWhenIdle,
    isOpen,
    onClose
  } = props;
  const [ currentImageIndex, setCurrentImageIndex ] = useState(0);
  const [ showThumbnails ] = useState(true);

  const gotoPrevious = () => {
    setCurrentImageIndex((prevIndex: number) => (prevIndex > 0 ? prevIndex  : images.length) - 1);
  }

  const gotoNext = () => {
    setCurrentImageIndex((prevIndex: number) => prevIndex < images.length - 1 ? prevIndex + 1  : 0);
  }

  const handleClickPhoto = () => {
    setCurrentImageIndex((prevIndex: number) => prevIndex < images.length - 1 ? prevIndex + 1  : 0);
  }

  const handleOnClose = () => {
    setCurrentImageIndex((prevIndex: number) => !keepCurrentOnClose ? 0 : prevIndex);
    onClose && onClose();
  }

  return (
    <ReactBnbGallery
      activePhotoIndex={currentImageIndex}
      leftKeyPressed={gotoPrevious}
      prevButtonPressed={gotoPrevious}
      rightKeyPressed={gotoNext}
      nextButtonPressed={gotoNext}
      activePhotoPressed={handleClickPhoto}
      onClose={handleOnClose}

      show={isOpen}
      photos={images.map((details: ImageDetails, index: number) => ({
        /* The source (`src`) of the photo. */
        photo: details.source,
        /* The current number of the photo. */
        number: index,
        /* Short description of the photo. */
        caption: details.caption,
        /* Secondary description. Can be used to show the photo author or the name or the place where it was taken. */
        subcaption: details.short
      }))}
      showThumbnails={showThumbnails}
      backgroundColor="#000E" />
  )
};

ImageGalleryModal.propTypes = {
  images: PropTypes.arrayOf(PropTypes.shape({
    source: PropTypes.string,
    alt: PropTypes.string,
    short: PropTypes.string,
    caption: PropTypes.string
  })).isRequired,
  keepCurrentOnClose: PropTypes.bool.isRequired,
  hideControlsWhenIdle: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.bool
  ]).isRequired,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired
};

ImageGalleryModal.defaultProps = {
  keepCurrentOnClose: false,
  hideControlsWhenIdle: false,
  isOpen: false
};

export default ImageGalleryModal;