import * as Copyright from './Copyright';
import * as Langs from './Langs';
import * as Routing from './Routing';

const Constants = {
  Copyright,
  Langs,
  Routing,
};

export default Constants;