import React from 'react';
import PropTypes from 'prop-types';
import { CustomPropTypes } from '../../utils';

import { IconProps } from '@misc';

import './style.scss';
import './medias.scss';

const Icon = (props: IconProps) => {
  const {
    size,
    name,
    color,
    hoverColor,
    className,
    onClick
  } = props;

  return (
    <span className={`icon icon-${name} icon-${size} ${className} icon-color-${color} ${hoverColor ? `icon-hover-color-${hoverColor}` : ''}`}
          onClick={onClick} />
  )
};

Icon.propTypes = {
  size: CustomPropTypes.IconSize.isRequired,
  name: CustomPropTypes.IconName.isRequired,
  color: CustomPropTypes.IconColor.isRequired,
  hoverColor: CustomPropTypes.IconColor,
  className: PropTypes.string,
  onClick: PropTypes.func,
};

Icon.defaultProps = {
  size: 'md',
  color: 'black',
  className: '',
};

export default Icon;