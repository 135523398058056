import { types } from 'mobx-state-tree';

import ImageDetails from './ImageDetails';

const { model, string, array } = types;

const ProjectBlock = model('ProjectBlock', {
  title: string,
  descr: string,
  asset: string,
  technosicon: array(string),
  gallery: array(ImageDetails)
});

export default ProjectBlock;