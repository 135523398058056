import { types } from 'mobx-state-tree';

import { LinkItemDetails, InfoDetail } from './submodels';

const { model, literal, string, array } = types;

const ContactSectionModel = model('ContactSectionModel', {
  type: literal('contact'),
  email: string,
  links: array(LinkItemDetails),
  infos: array(InfoDetail),
});

export default ContactSectionModel;