import React from 'react';
import PropTypes from 'prop-types';

import { InformationModalHeaderProps } from '@misc';

import Icon from '../../../Icon';

import './style.scss';
import './medias.scss';

const InformationModalHeader = (props: InformationModalHeaderProps) => {
  const {
    title,
    onCloseModal
  } = props;

  return (
    <div className="information-modal-header">
      <div className="information-modal-title">
        {title}
      </div>
      <Icon className="information-modal-close-button" color="white" name="cross" onClick={onCloseModal} />
    </div>
  )
};

InformationModalHeader.propTypes = {
  title: PropTypes.string.isRequired,
  onCloseModal: PropTypes.func.isRequired,
};

InformationModalHeader.defaultProps = {};

export default InformationModalHeader;