import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { CopyrightModalFooterProps } from '@misc';

import I18nTrans from '../../../../../I18nTrans';

import './style.scss';

const CopyrightModalFooter = (props: CopyrightModalFooterProps) => {
  const {
    yearStart,
    yearEnd,
    owner
  } = props;
  const { t } = useTranslation('common');
  
  return (
    <div className="copyright-modal-footer">
      <p>
        <I18nTrans
          i18nKey="modals.information.footer.copyright.copy"
          values={{
            yearStart,
            yearEnd,
            owner
          }} />
        <br/>
        {t('modals.information.footer.copyright.rights')}
      </p>
    </div>
  )
};

CopyrightModalFooter.propTypes = {
  yearStart: PropTypes.number.isRequired,
  yearEnd: PropTypes.number.isRequired,
  owner: PropTypes.string.isRequired
};

CopyrightModalFooter.defaultProps = {
  yearEnd: new Date().getFullYear()
};

export default CopyrightModalFooter;