import { types } from 'mobx-state-tree';

const { model, maybeNull, string } = types;

const ImageDetails = model('ImageDetails', {
  source: string,
  alt: maybeNull(string),
  short: maybeNull(string),
  caption: maybeNull(string)
});

export default ImageDetails;