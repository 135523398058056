import { types } from 'mobx-state-tree';

import { SkillsGroupBlock } from './submodels';

const { model, literal, array } = types;

const SkillsSectionModel = model('SkillsSectionModel', {
  type: literal('skills'),
  groups: array(SkillsGroupBlock)
});

export default SkillsSectionModel;