import { types } from 'mobx-state-tree';

const { model, maybeNull, string, array, number } = types;

const SkillItem = model('SkillItem', {
  name: string,
  icon: string,
  facts: maybeNull(array(string)),
  level: number
});

export default SkillItem;