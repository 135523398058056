import { types } from 'mobx-state-tree';

import ExperienceItemDetails from './ExperienceItemDetails';

const { model, maybeNull, string, array } = types;

const ExperienceBlock = model('ExperienceBlock', {
  label: string,
  content: maybeNull(array(ExperienceItemDetails)),
});

export default ExperienceBlock;