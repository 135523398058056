import { types } from 'mobx-state-tree';

const { model } = types;

const AdminStore = model('AdminStore', {
  
})/*.views(self => ({

})).actions(self => ({
  
}))*/.create({

});

export default AdminStore

