import { flow } from 'mobx';
import { types } from 'mobx-state-tree';

import { SectionModel } from './models/abstract';
import { FrenchData, EnglishData } from './datasource';
import {
  DataSource,
  AboutSectionModel,
  ExpsSectionModel,
  SkillsSectionModel,
  ProjectsSectionModel,
  PassionsSectionModel,
  ContactSectionModel
} from '@porfolio/models';
import Constants from '../../utils/Constants';

const { model, array, string } = types;
const { Langs } = Constants;

const PortfolioStore = model('PortfolioStore', {
  lang: string,
  sections: array(SectionModel),
}).views(self => ({
  getLang() {
    return self.lang
  }
})).actions(self => ({
  setLang(lang: string) {
    self.lang = Langs.availableLanguages.includes(lang) ? lang : Langs.defaultLanguage;
  },
  populate: flow(function* populate() {
    const data: DataSource = self.lang === 'fr' ? FrenchData : EnglishData;

    // About
    const about: AboutSectionModel = data.about;
    self.sections.push({
      tag: 'about',
      color: '#3E4651',
      content: {
        type: 'about',
        ...about
      },
    })

    // Experiences
    const exps: ExpsSectionModel = data.exps;
    self.sections.push({
      tag: 'exps',
      color: '#2980B9',
      content: {
        type: 'exps',
        ...exps
      },
    })

    // Skills
    const skills: SkillsSectionModel = data.skills;
    self.sections.push({
      tag: 'skills',
      color: '#37BC9B',
      content: {
        type: 'skills',
        ...skills
      },
    })

    // Projects
    const projects: ProjectsSectionModel = data.projects;
    self.sections.push({
      tag: 'projects',
      color: '#34495E',
      content: {
        type: 'projects',
        ...projects
      },
    })

    // Passions
    const passions: PassionsSectionModel = data.passions;
    self.sections.push({
      tag: 'passions',
      color: '#977BAB',
      content: {
        type: 'passions',
        ...passions
      },
    })

    // Contact
    const contact: ContactSectionModel = data.contact;
    self.sections.push({
      tag: 'contact',
      color: '#DA4453',
      content: {
        type: 'contact',
        ...contact
      },
    })
  }),
})).create({
  lang: navigator.language ? navigator.language.substring(0, 2) : Langs.defaultLanguage,
  sections: []
});

export default PortfolioStore

