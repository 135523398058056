import { types } from 'mobx-state-tree';

const { model, maybeNull, string } = types;

const InfoDetail = model('InfoDetail', {
  title: string,
  descr: maybeNull(string),
  url: maybeNull(string),
  icon: string
});

export default InfoDetail;