import { types } from 'mobx-state-tree';

import { ProjectBlock } from './submodels';

const { model, literal, array } = types;

const ProjectsSectionModel = model('ProjectsSectionModel', {
  type: literal('projects'),
  projects: array(ProjectBlock)
});

export default ProjectsSectionModel;