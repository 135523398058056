import React, { lazy } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';

import Constants from '../utils/Constants';

const Portfolio = lazy(() => import('../portfolio'));
// const Admin = lazy(() => import ('../admin'));

const Router = () => {
  const {
    Routing: {
      adminRoot
    }
  } = Constants;

  return (
    <Routes>
      <Route path="/" element={<Portfolio />} />
      {/*<Route path={`/${adminRoot}/*`} element={<Admin />} />*/}
      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  )
};

export default Router;
