import React, { Suspense } from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'mobx-react';
import { RouterStore } from 'mobx-react-router';
import { BrowserRouter } from 'react-router-dom';

import { Loader } from './components';
import './styles/index.css';
import Router from './router';

import * as stores from './stores';
const MobxStores = {...stores, routing: new RouterStore()};

const root: HTMLElement | null = document.getElementById('root');
if (root) {
  createRoot(root).render(
    <div id="home">
      <Suspense fallback={<Loader />}>
        <Provider {...MobxStores}>
          <BrowserRouter>
            <Router />
          </BrowserRouter>
        </Provider>
      </Suspense>
    </div>
  );
}

// TODO: Fix, add strict mode (issue comes from react-tooltip & ItemWithTooltip component)
/*
if (root) {
  createRoot(root).render(
    <div id="home">
      <Suspense fallback={<Loader />}>
        <React.StrictMode>
          <Provider {...MobxStores}>
            <Router />
          </Provider>
        </React.StrictMode>
      </Suspense>
    </div>
  );
}
*/
