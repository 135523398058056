import { types } from 'mobx-state-tree';
import {
  AboutSectionModel,
  ExpsSectionModel,
  SkillsSectionModel,
  ProjectsSectionModel,
  PassionsSectionModel,
  ContactSectionModel,
} from '../sections';

const { model, string, optional, union } = types;

const SectionModel = model('SectionModel', {
  color: string,
  tag: string,
  content: optional(union(AboutSectionModel, ExpsSectionModel, SkillsSectionModel, ProjectsSectionModel, PassionsSectionModel, ContactSectionModel, types.null), null)
});

export default SectionModel;