import React from 'react';
import PropTypes from 'prop-types';
import { CustomPropTypes } from '../../utils';

import { CircledIconProps } from '@misc';

import './style.scss';
import './medias.scss';

const CircledIcon = (props: CircledIconProps) => {
  const {
    size,
    name,
    color,
    backgroundColor,
    hoverColor,
    hoverBackgroundColor,
    borderType,
    borderColor,
    className,
    onClick
  } = props;

  return (
    <div onClick={onClick} className={`circled-icon ${className} circled-icon-bg-${backgroundColor} ${hoverBackgroundColor ? `circled-icon-hover-bg-${hoverBackgroundColor}` : ''} circled-icon-border-${borderColor} circled-icon-border-${borderType} icon-color-${color} ${hoverColor ? `icon-hover-color-${hoverColor}` : ''}`}>
      <span className={`icon icon-${name} icon-${size}`} />
    </div>
  )
};

CircledIcon.propTypes = {
  size: CustomPropTypes.IconSize.isRequired,
  name: CustomPropTypes.IconName.isRequired,
  color: CustomPropTypes.IconColor.isRequired,
  backgroundColor: CustomPropTypes.CircledIconBackgroundColor.isRequired,
  hoverColor: CustomPropTypes.IconColor,
  hoverBackgroundColor: CustomPropTypes.CircledIconBackgroundColor,
  borderType: CustomPropTypes.CircledIconBorderType.isRequired,
  borderColor: CustomPropTypes.CircledIconBorderColor.isRequired,
  className: PropTypes.string,
  onClick: PropTypes.func,
};

CircledIcon.defaultProps = {
  size: 'md',
  color: 'black',
  backgroundColor: 'transparent',
  borderType: 'solid',
  borderColor: 'white',
  className: '',
};

export default CircledIcon;