import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { I18nTransProps } from '@misc';

const I18nTrans = (props: I18nTransProps) => {
  const {
    namespace,
    i18nKey,
    values,
  } = props;
  const { t } = useTranslation(namespace);

  let trans = t(i18nKey);
  for (const key of Object.keys(values)) {
    trans = trans.replace(`{{ ${key} }}`, values[key]);
  }

  return (
    <>{trans}</>
  )
};

I18nTrans.propTypes = {
  namespace: PropTypes.string.isRequired,
  className: PropTypes.string,
  values: PropTypes.object.isRequired
};

I18nTrans.defaultProps = {
  namespace: 'common',
  className: ''
};

export default I18nTrans;