import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { InformationModalProps } from '@misc';

import { InformationModalFooter, InformationModalHeader } from './components';

import './style.scss';
import './medias.scss';

const InformationModal = (props: InformationModalProps) => {
  const {
    title,
    isOpen,
    onClose,
    children,
    footer
  } = props;
  const { t } = useTranslation('common');

  return (
    <section className={`information-modal ${isOpen ? 'modal-opened' : ''}`}>
      <div className="information-modal-container">
        <InformationModalHeader title={title ?? t('modals.information.header.title')} onCloseModal={onClose} />
        <div className="information-modal-content">
          {children}
        </div>
        {footer && <InformationModalFooter footer={footer} />}
      </div>
    </section>
  )
};

InformationModal.propTypes = {
  title: PropTypes.string,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  footer: PropTypes.oneOfType([
    PropTypes.shape({
      type: PropTypes.oneOf([ 'copyright' ]),
      yearStart: PropTypes.number.isRequired,
      yearEnd: PropTypes.number,
      owner: PropTypes.string.isRequired
    }),
    PropTypes.shape({
      type: PropTypes.oneOf([ 'action' ]),
      onConfirm: PropTypes.func.isRequired,
      onCancel: PropTypes.func.isRequired
    })
  ])
};

InformationModal.defaultProps = {
  isOpen: false
};

export default InformationModal;