import { types } from 'mobx-state-tree';

import { PassionBlock } from './submodels';

const { model, literal, array } = types;

const PassionsSectionModel = model('PassionsSectionModel', {
  type: literal('passions'),
  passions: array(PassionBlock)
});

export default PassionsSectionModel;