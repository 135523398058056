import { types } from 'mobx-state-tree';

const { model, string, boolean } = types;

const ExperienceItemDetails = model('ExperienceItemDetails', {
  start: string,
  end: string,
  title: string,
  subtitle: string,
  descr: string,
  url: string,
  asset: string,
  current: boolean
});

export default ExperienceItemDetails;