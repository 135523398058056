import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { ActionModalFooterProps } from '@misc';

import './style.scss';

const ActionModalFooter = (props: ActionModalFooterProps) => {
  const {
    disabled,
    onConfirm,
    onCancel,
  } = props;
  const { t } = useTranslation('common');
  
  return (
    <div className="action-modal-footer">
      <button className="action-button action-button-confirm" disabled={disabled} onClick={onConfirm}>
        {t('buttons.confirm')}
      </button>
      <button className="action-button action-button-cancel" disabled={disabled} onClick={onCancel}>
        {t('buttons.cancel')}
      </button>
    </div>
  )
};

ActionModalFooter.propTypes = {
  disabled: PropTypes.bool.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired
};

ActionModalFooter.defaultProps = {
  disabled: false
};

export default ActionModalFooter;