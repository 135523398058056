import { types } from 'mobx-state-tree';

import { ExperienceBlock } from './submodels'

const { model, literal, array } = types;

const ExpsSectionModel = model('ExpsSectionModel', {
  type: literal('exps'),
  exps: array(ExperienceBlock)
});

export default ExpsSectionModel;