import { types } from 'mobx-state-tree';

const { model, maybeNull, string } = types;

const AuthorizationStore = model('AuthorizationStore', {
  auth_token: maybeNull(string),
})/*.views(self => ({

})).actions(self => ({

}))*/.create({
  auth_token: null,
});

export default AuthorizationStore;

