import { types } from 'mobx-state-tree';

import SkillItem from './SkillItem';

const { model, string, array } = types;

const SkillsGroupBlock = model('SkillsGroupBlock', {
  label: string,
  skills: array(SkillItem),
});

export default SkillsGroupBlock;