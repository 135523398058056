import React from 'react';
import PropTypes from 'prop-types';
import { CustomPropTypes } from '../../utils';

import ReactTooltip from 'react-tooltip';
import { ItemWithTooltipProps } from '@misc';

import './style.scss';

const ItemWithTooltip = (props: ItemWithTooltipProps) => {
  const {
    id,
    className,
    position,
    type,
    effect,
    tooltip,
    children
  } = props;

  return (
    <div className={`item-with-tooltip ${className}`}>
      <div data-tip data-for={id}>
        {children}
      </div>
      <ReactTooltip id={id} place={position} type={type} effect={effect}>
        {tooltip}
      </ReactTooltip>
    </div>
  )
};

ItemWithTooltip.propTypes = {
  id: PropTypes.string.isRequired,
  position: CustomPropTypes.TooltipPosition.isRequired,
  type: CustomPropTypes.TooltipType.isRequired,
  effect: CustomPropTypes.TooltipEffect.isRequired,
};

ItemWithTooltip.defaultProps = {
  position: 'bottom',
  type: 'dark',
  effect: 'float',
  className: ''
};

export default ItemWithTooltip;