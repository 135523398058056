import { types } from 'mobx-state-tree';

import ImageDetails from './ImageDetails';

const { model, string, array } = types;

const PassionBlock = model('PassionBlock', {
  label: string,
  icon: string,
  gallery: array(ImageDetails)
});

export default PassionBlock;